<template>
  <PageHeaderLogged backshow="true" menu="false"/>
    <div class="wrapper graybg">
 	<div class="underwrapper userfull_wrapper">
 	<div class="carousel_wrapper loadingel">
 	<carousel :items-to-show="1" :mouse-drag="false" :touch-drag="false" >
    <slide v-for="photo in photos" :key="photo">
  <div class="profile_photo_wrapper" :style="{ backgroundImage: 'url(' + photo.x + ')' }"></div>   
    </slide>

    <template #addons>
       <Pagination/>
      <Navigation/>
    </template>
  </carousel>
 	</div>
 	
 	<div class="profile_title_username  loadingel">{{ username }}</div>
	<ul class="profile_info">
	<li><div class="loadingel">{{ height }} см</div></li>
	<li><div class="loadingel">Дети: {{ children }}</div></li>
	</ul>  
  </div></div>
</template>
<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination,Navigation } from 'vue3-carousel'

export default {
	
	name: 'UserView', 
	components: {
    Carousel,
    Slide,
    Pagination,Navigation
  },
	data: function () {
    return {
     loading:true,
      children: 'children',
      username: 'username',
      height: 'height',
      photos:[],
    }
  },
  mounted(){
  
  
  	$(".carousel__track").mousemove(function(){
  			let matrix = $(this).css('transform');
  			let matrixA = matrix.split(',');
			let min = 20;
			let max = $(".carousel__slide").width() * $(".carousel__slide").length  - $(".carousel__slide").width() * ($(".carousel__slide").length+1);
			
  			if (matrixA[4] > min)
		  		$(this).css('transform','translateX(20px)');
  			if (matrixA[4] < max - 20)
		  		$(this).css('transform','translateX('+(max-20)+'px)');
		  		
  	})
  
  },
   created() {
  // return;
    this.$store.dispatch("user/profile").then(
	        (user) => {
	            this.loading = false;
	            this.username = user.name +', '+user.age;
	            this.height = user.height == null ? '170 см' : user.height;
	            
	            this.children = this.formatChildren(user.children);
	            
	            if (user.photo){
		            this.photos.push(user.photo);
	            }
	            if (user.photos){
	            	for(let i=0;i<user.photos.length;i++)
			            this.photos.push(user.photos[i]);
		        }
	        //    console.log(this.photos[0].m);
	            
	            let i = 0;
	            let _self = this;
	            $(user.photos).each(function(){
	            	switch(i){
	            		case 0:_self.image1=this;break;
	            		case 1:_self.image2=this;break;
	            		case 2:_self.image3=this;break;
	            		case 3:_self.image4=this;break;
	            	}
	            	i++;
	            });
	            
	            //if (user.children  != null) children = user.children; 
	        },
	        (error) => {
	          this.loading = false;
	        }
	 );
  },
  watch: {
    loading: ()=>{$(".loadingel").removeClass('loadingel');}
   }
 }
 </script>