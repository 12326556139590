<template>
  <PageHeaderLogged title="Профиль" iam="true"/>
  
   <div class="wrapper graybg">
   <div class="underwrapper">
   <div class="photo_block_profile">
   	<div class="main_photo"><div class="photo_wrapper loadingel"><Photo type="main" :image="image0" sort="0"/></div></div>
   	<div class="addphoto_wrapper">
   		<div class="photo_wrapper loadingel"><Photo type="add" :image="image1" sort="1" /></div>
   		<div class="photo_wrapper loadingel"><Photo type="add" :image="image2" sort="2"/></div>
   		<div class="photo_wrapper loadingel"><Photo type="add" :image="image3" sort="3"/></div>
   		<div class="photo_wrapper loadingel"><Photo type="add" :image="image4" sort="4"/></div>
   	</div>
   	
   </div>
   
   
   <div class="whiteblock">
   
  		<div class="reg_row reg_row_textwraper ">
   				<div class="editlabel editlabelusername loadingel"><input readonly type="text" v-model="username"> <span class="pencil" v-on:click="editme('usernameedit')"></span></div>
   				
   				
   				
   				
		</div>
  
  	<div class="reg_row reg_row_textwraper ">
   			
  		<div class="col2">
  			<div>
   				<div class="editlabel loadingel ">
   					{{ age }} <span class="pencil" v-on:click="editme('ageedit')"></span></div>
<modal-window ref="modal">
					<template v-slot:title>	<h3 class="modal-title">Дата рождения</h3> </template>
 <template v-slot:body>
 
 <div class="reg_row reg_row_graybg">
		      <div class="dat_years_wrapper"> <div> <Date v-model="date" :dayValue="date.day" :monthValue="date.month" :yearValue="date.year"/></div>
		      	<div class="total_year">{{ yearsTotal }}</div>
		      </div>	
		      <div class="form-input-error">{{ yearerror }}</div>
</div>
 
 
 </template>    <template v-slot:footer-okbtn> <button class="modal-footer__button" @click="saveBd">    Ок  </button> </template>
       
 
</modal-window>

<modal-window ref="errorDialog">
					<template v-slot:title>	<h3 class="modal-title">Внимание</h3> </template>
 <template v-slot:body> Ошибка сохранения </template>    <template v-slot:footer-okbtn> &nbsp; </template>
       
 
</modal-window>
  			
  			</div>
  			<div>
   				<div class="reg_row_selectwraper " id="heightedit">
   				<div class="editlabel loadingel">{{ height }} <span class="pencil"></span></div>
   				<select name="agecount"  @change="heightedit($event)">
   					<option value="150">150 см</option>   					<option value="151">151 см</option>    					<option value="152">152 см</option>   					<option value="153">153 см</option>    					<option value="154">154 см</option>   					<option value="155">155 см</option>    					<option value="156">156 см</option>   					<option value="157">157 см</option>    					<option value="158">158 см</option>   					<option value="159">159 см</option>   					<option value="160">160 см</option>   					<option value="161">161 см</option>   					<option value="162">162 см</option>   					<option value="163">163 см</option>   					<option value="164">164 см</option>   					<option value="165">165 см</option>    					<option value="166">166 см</option>   					<option value="167">167 см</option>   					<option value="168">168 см</option>   					<option value="169">169 см</option>   					<option value="170">170 см</option>   					<option value="171">171 см</option>
   					<option value="172">172 см</option><option value="173">173 см</option><option value="174">174 см</option><option value="175">175 см</option><option value="176">176 см</option><option value="177">177 см</option><option value="178">178 см</option><option value="179">179 см</option><option value="180">180 см</option><option value="181">181 см</option><option value="182">182 см</option><option value="183">183 см</option><option value="184">184 см</option><option value="185">185 см</option><option value="186">186 см</option><option value="187">187 см</option><option value="188">188 см</option><option value="189">189 см</option><option value="190">190 см</option>
   					
   					<option value="191">191 см</option>
   					<option value="192">192 см</option>
   					<option value="193">193 см</option>
   					<option value="194">194 см</option>
   					<option value="195">195 см</option>
   					<option value="196">196 см</option>
   					<option value="197">197 см</option>
   					<option value="198">198 см</option>
   					<option value="199">199 см</option>
   					<option value="200">200 см</option>
   					<option value="201">201 см</option>
   					<option value="202">202 см</option>
   					<option value="203">203 см</option>
   					<option value="204">204 см</option>
   					<option value="205">205 см</option>
   					<option value="206">206 см</option>
   					<option value="207">207 см</option>
   					<option value="208">208 см</option>
   					<option value="209">209 см</option>
   					<option value="210">210 см</option>
   					
   				</select>
		</div>
  			
  			</div>
  		</div>
  </div>		
  		
  		<div class="reg_row reg_row_selectwraper  " id="childrenedit" >
   				<div class="editlabel loadingel">Дети: {{ children }} <span class="pencil"></span></div>
   				<select name="childrencount" @change="chidrenedit($event)">
   					<option value="null">Не указано</option>
   					<option value="1">Пока нет</option>
   					<option value="2">Есть</option>
   				</select>
		</div>
   </div>
   
   </div>
   </div>
     <BottomPanel activeprofile="active"/>
   
</template>
<script>
import ModalWindow from '../components/modal-window.vue'

export default {
	
	name: 'ProfileView', 
     components: {
       ModalWindow
        },
     	
	data: function () {
    return {
     loading:true,
      children: 'Не указано',
      username: 'чч',
      date:{day:1,month:1,year:2001},
       yearsTotal:"18 лет" ,	
 	   height: 'Нет',
      age: '.',
      bd:'',
      image0:'',
      image1:'',
      image2:'',
      image3:'',
      image4:'',
    }
  },
  mounted(){
   		this.setHeight('wrapper.graybg');
  
  
  },
  created() {
  
    this.$store.dispatch("user/profile").then(
	        (user) => {
	            this.loading = false;
	            this.username = user.name;
	            this.height = user.height == null ? '170 см' : user.height;
	            this.age = user.ageStr;
	            this.children = this.formatChildren(user.children);
	            if (user.children != null)
					$("select[name=childrencount]").val(user.children);
	            $("select[name=agecount]").val(user.age);
	            
	            this.bd = user.bd;
	            let  b = this.bd.split('-');
	            this.date.day = parseInt(b[2]);
	            this.date.month= parseInt(b[1]);
	            this.date.year = parseInt(b[0]);
	        //    console.log(this.date);
///	            this.image0='xxx';
	            
	            if (user.photo){
		            this.image0=user.photo;
	            }
	            let i = 0;
	            let _self = this;
	            $(user.photos).each(function(){
	            	switch(parseInt(this.sort)){
	            		case 1:
	            		_self.image1=this;
	            		
	            		break;
	            		case 2:_self.image2=this;break;
	            		case 3:_self.image3=this;break;
	            		case 4:_self.image4=this;break;
	            	}
	            	i++;
	            });
	            //if (user.children  != null) children = user.children; 
	        },
	        (error) => {
	          this.loading = false;
//	          this.$router.push('/');
	        }
	 );
  },
  methods: {
  calcYear(val){
	    var birthDate = new Date(val.year, val.month-1, val.day);  
	    var  otherDate = new Date();
	    var age = (otherDate.getFullYear() - birthDate.getFullYear());
	    if (otherDate.getMonth() < birthDate.getMonth() ||
	        otherDate.getMonth() == birthDate.getMonth() && otherDate.getDate() < birthDate.getDate()) {
	    	age--;
	    }
		

//    const age =Math.floor((dateToCompare - dob) / (365 * 24 * 60 * 60 * 1000));
    	    this.yearsTotal = age + ' ' +  this.pluralage(age);
    	    this.years = age ;
    	 	if (age <18){
    			this.yearerror = 'Сервисом можно пользоваться только 18+ лет';	
    		}
    		else
    			this.yearerror = '';
    		
    //	    this.birthdate = checkindate; 
	},
    saveField(field, newVal){
    	this.$store.dispatch("user/saveField", {field:field,value:newVal}).then(
	        (answer) => {
	           this.loading = false;
	           if (answer.success){
	          // this.formatImage(answer.format);
	           	
	           		if(field == 'birthdate'){
		           		this.$refs.modal.show = false;
}  
	           }
	           else{
	           		if(field == 'birthdate'){
	           			this.yearerror = 'Некорректная дата, проверьте дату';
	           			//$(".popup_body .form-input-error").html('Некорректная дата, проверьте дату');
	           		}
	           		else{
	           	    
	           	        this.$refs.errorDialog.show = true
 					}
	           }
	        },
	        (error) => {
	          this.loading = false;
	        }
	 );
    },
  	saveBd() {
  		if(this.yearerror!='') return;
  		
  	//	var checkindate = new Date(this.bd);
	 		var checkindate = new Date(this.date.year, this.date.month-1, this.date.day);   
			 this.bd = 	this.date.year + '-'+ this.date.month+'-'+this.date.day;
        	 var now = new Date();
		    	 
		    	 const dob = checkindate.getTime();
		    	    const dateToCompare = now.getTime();
		    	    let age =Math.floor((dateToCompare - dob) / (365 * 24 * 60 * 60 * 1000));
		    	    
		    	    if (now.getMonth() < checkindate.getMonth() ||
				        now.getMonth() == checkindate.getMonth() && now.getDate() < checkindate.getDate()) {
	    					age--;
					    }
		
		    	    
		    	    
		    	    this.age = age + ' ' +  this.pluralage(age);
//		    		this.birthdate = checkindate; 
  		this.saveField('birthdate',this.bd);
  			
  	},
  		
  	chidrenedit(event){
  	        this.children = this.formatChildren(event.target.value);
        	this.saveField('children',event.target.value);
  	
  	},
  	heightedit(event){
  	        this.height =event.target.value + ' см';
        	this.saveField('height',event.target.value);
  	},
  	editme(el){
  		if (el == 'ageedit'){
  		       let  b = this.bd.split('-');
	            this.date.day = b[2];
	            this.date.month= b[1];
	            this.date.year = b[0];
	    
  			this.$refs.modal.show = true
  			return;  		
  		}
  		if (el == 'usernameedit' && !$(".editlabelusername").find('.pencil').hasClass('save')){
	  		$(".editlabelusername").find('.pencil').addClass('save');
  			$(".editlabelusername").find('input[type=text]').removeAttr('readonly').focus();
  		}
  		else{
	  		$(".editlabelusername").find('.pencil').removeClass('save');
  				this.saveField('name',this.username);
  
  		}
  		
  	}
  },
  watch: {
    loading: ()=>{$(".loadingel").removeClass('loadingel');},
    date: {
		     handler(val){
		     	
		    	this.calcYear(val)
		 		    
		     },
		     deep: true
		  }
	  },
   
}	
</script>