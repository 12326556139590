import axios from 'axios';
import mixin from './../plugins/mixin';

import authHeader from './auth-header';


class UserService {
  apiUrl = mixin.methods.config('apiUrl') + 'profile/'

  savePhoto(form){
	  return axios.post(this.apiUrl + 'savephoto', form, { headers: authHeader() }).then(response => {
	    	//if (response.data.success == 1) {
	            return response.data;
	            
	       // }
	        });
	  
  }
  saveFilters(form){
	  return axios.post(this.apiUrl + 'savefilters', form, { headers: authHeader() }).then(response => {
	    	//if (response.data.success == 1) {
	            return response.data;
	            
	       // }
	        });
	  
  }
  saveField(field){
	  return axios.post(this.apiUrl + 'savefield', field, { headers: authHeader() }).then(response => {
	    	//if (response.data.success == 1) {
	            return response.data;
	            
	       // }
	        });
  }
  checkIn(coordinate){
	  return axios.post(this.apiUrl + 'checkin', coordinate, { headers: authHeader() }).then(response => {
	    	//if (response.data.success == 1) {
	            return response.data;
	            
	       // }
	        });
  }
  updateLoc(coordinate){
	  return axios.post(this.apiUrl + 'updateloc', coordinate, { headers: authHeader() }).then(response => {
	    	//if (response.data.success == 1) {
	            return response.data;
	            
	       // }
	        });
  }
  checkOut(placeId){
	  return axios.post(this.apiUrl + 'checkout', placeId, { headers: authHeader() }).then(response => {
	    	//if (response.data.success == 1) {
	            return response.data;
	       // }
        });
  }
  getPlaceInfo(placeId){
	  return axios.post(this.apiUrl + 'getplaceinfo', placeId, { headers: authHeader() }).then(response => {
	    	//if (response.data.success == 1) {
	            return response.data;
	       // }
        });
  }
  getDefaultLocation(){
	  return axios.post(this.apiUrl + 'getdefaultlocation', {}, { headers: authHeader() }).then(response => {
	    	//if (response.data.success == 1) {
	            return response.data;
	       // }
        });
  }
  deletePlace(placeId){
	  return axios.post(this.apiUrl + 'deleteplace', placeId, { headers: authHeader() }).then(response => {
	    	//if (response.data.success == 1) {
	            return response.data;
	       // }
        });
  }
  setActivePlace(placeId){
	  return axios.post(this.apiUrl + 'setactiveplace', placeId, { headers: authHeader() }).then(response => {
	    	//if (response.data.success == 1) {
	            return response.data;
	       // }
        });
  }
  changePhotoToMain(id){
	  return axios.post(this.apiUrl + 'chnagephototomain', {id:id}, { headers: authHeader() }).then(response => {
          return response.data;
	  });
	  
  } 
  deletePhoto(id){
	  return axios.post(this.apiUrl + 'deletephoto', {id:id}, { headers: authHeader() }).then(response => {
	            return response.data;
      });
  
  }
  delaccount() {
	  return axios.post(this.apiUrl + 'delaccount', {}, { headers: authHeader() }).then(response => {
          return response.data;
});
  }
  requestChangeEmail(data){
	  return axios.post(this.apiUrl + 'requestchangeemail', data, { headers: authHeader() }).then(response => {
	            return response.data;
      });
  
  }
  changepwd(data){
	  return axios.post(this.apiUrl + 'changepwd', data, { headers: authHeader() }).then(response => {
	            return response.data;
      });
  
  }
  getProfile() {
    return axios.get(this.apiUrl + 'get', { headers: authHeader() }).then(response => {
    	if (response.data.success == 1) {
            return response.data.user;
            
        }
    });
  }
  getFilters() {
	    return axios.get(this.apiUrl + 'filters', { headers: authHeader() }).then(response => {
	    	if (response.data.success == 1) {
	            return response.data.filters;
	            
	        }
	    });
	  }
  support(form) {
	    return axios.post(this.apiUrl + 'support', form, { headers: authHeader() }).then(response => {
	            return response.data;
	            
	    });
	  }
  
}

export default new UserService();